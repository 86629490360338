<template>
  <div :style="{
    'position': 'relative',
  }">
    <div :style="{
      'background': `url(${backgroundUrl})`,
      'background-size': 'cover',
      'background-position': 'center',
      'background-attachment': 'fixed',
      'height': '100%',
      'width': '100%',
      'position': 'absolute',
      'top': 0,
      'left': 0,
      'z-index': -1
    }"></div>
    <div :style="{
      'background-color': 'rgba(0, 0, 0, 0.3)', // Adjust the opacity as needed
      'height': '100%',
      'width': '100%',
      'position': 'absolute',
      'top': 0,
      'left': 0,
      'z-index': -1
    }"></div>
    <Linnapea_app v-if="$route.meta?.m2ng === 'linnapea'" />
    <Metsavend_app v-else-if="$route.meta?.m2ng === 'metsavend'" />
  </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import mainFuncV3 from '@/mainFuncV3'
import { toRefs } from "vue";
import { watch } from "vue";
import { ref } from "vue";
const Metsavend_app = defineAsyncComponent(() => import('./metsavend/Metsavend_app.vue'));
const Linnapea_app = defineAsyncComponent(() => import('./components/m2ng/Linnapea_app.vue'));
const {route} = mainFuncV3();
const metadata = toRefs(route);
const backgroundUrl = ref();
const aasta_ajad = {
  'kevad': require('../public/metsavend_kevad.jpeg'),
  'suvi': require('../public/metsavend_suvi.jpeg'),
  'sügis': require('../public/metsavend_sygis.jpeg'),
  'talv': require('../public/metsavend_talv.jpeg'),
}

const getCurrentSeason = () => {
  const month = new Date().getMonth();
  switch (true) {
    case month >= 2 && month <= 4:
      return 'kevad';
    case month >= 5 && month <= 7:
      return 'suvi';
    case month >= 8 && month <= 10:
      return 'sügis';
    default:
      return 'talv';
  }
}

backgroundUrl.value = aasta_ajad[getCurrentSeason()];

watch(() => metadata.meta.value, (newvalue , oldvalue) => {
  if (newvalue !== oldvalue) {
      backgroundUrl.value = newvalue?.m2ng === 'linnapea' ? require('../public/linnapeataust.jpg') : aasta_ajad[getCurrentSeason()] 
  }
})
</script>
<style>

:root{
  --sinuandmed_link : ligthrey;
}

#app {
  /* background: url("v-bind(backgroundUrl)") #2c3e50; */
  /* background: url("https://www.metsavend.ee/metsavend_background.jpg") #2c3e50; */
  
  background-size: auto;
  font-family: Arial;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 0px;
  height: 100%;
  padding-bottom: 50px;
}

body {
  background: #2D2E31;
  --van-white: #fff;
  --van-blue: #1989fa;
  --van-button-primary-color: var(--van-white);
  --van-button-primary-background: var(--van-primary-color);
}
</style>
