// import '@coreui/coreui/dist/css/coreui.min.css'
import {  createApp } from 'vue'
import App from './App.vue'
import { Button, Form, Field, Icon, Tab, Tabs, Dialog, ActionSheet, Cell, CellGroup, Collapse, CollapseItem, Grid, GridItem, Tabbar, TabbarItem, DropdownMenu, DropdownItem, Badge,
  Locale, Col, Row, NoticeBar, Card, Sidebar, SidebarItem , Switch, NavBar, Popup, RadioGroup, Radio, Checkbox, CheckboxGroup, Divider, ConfigProvider, Image as VanImage } from 'vant';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueCookieNext } from 'vue-cookie-next'
import VueNumberFormat from 'vue-number-format'
import Notifications from '@kyvg/vue3-notification'

import enUS from 'vant/es/locale/lang/en-US';
import ProgressSpinner from 'primevue/progressspinner';
// import { CAlert,CFormInput,CFormSelect} from '@coreui/vue';
import { createI18n } from 'vue-i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faUserSecret, faBars, faInfo, faInfoCircle, faAngleLeft, faM, faS, faV, faK, faP, faO, faH, faL, faR, faD, faU,faT, faF, faB} from '@fortawesome/free-solid-svg-icons'
import {faEdit, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'
library.add(faUserSecret,faBars,faEdit,faTrashCan,faInfo,faInfoCircle,faAngleLeft, faM,faS,faV,faK,faP,faO,faH,faL,faK,faT,faR,faD,faM,faU,faF,faB);
import PrimeVue from 'primevue/config';
import InputNumber from 'primevue/inputnumber';
import "primevue/resources/themes/arya-blue/theme.css"
import PanelMenu from 'primevue/panelmenu';
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';
//keeled
import { splitLangJson } from './components/i18n'
import langs from '../public/Data/languages.json'
import router from './router.js'
import Countdown from 'vue3-flip-countdown';
import "@fontsource/jetbrains-mono"; // Defaults to weight 400
import "@fontsource/jetbrains-mono/400.css"; // Specify weight
import "@fontsource/jetbrains-mono/400-italic.css"; // Specify weight and style
import "./assets/fonts/fonts.scss";
// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives
})

import './assets/css/styles.scss'
import 'vant/lib/index.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './registerServiceWorker'
// Import Bootstrap and BootstrapVue CSS files (order is important)

const i18n = createI18n({
    lazy: true,
    legacy: false,
    locale: VueCookieNext.getCookie('language') ? VueCookieNext.getCookie('language') : 'EST', // set locale
    fallbackLocale:'EST',
    messages: splitLangJson(langs)

});
const app = createApp(App);

// app.config.warnHandler = (err, vm, info) => {
//     console.groupCollapsed(`%c Vue warning: ${(err.length > 70 ? err.slice(0, 70)+'...' : err)}`, 'color:gold');
//     console.warn(`%c ${err}`, 'color:gold');

//     // if (vm) {
//     //     console.groupCollapsed('%c Vue component', 'color:pink');
//     //     console.log(vm);
//     //     console.groupEnd();
//     // }

//     // if (info) {
//     //     console.groupCollapsed('%c Found_in', 'color:orange');
//     //     console.log('%c'+info.slice(17), 'color:orange;');
//     //     console.groupEnd();
//     // }

//     console.groupEnd();
// };

// app.config.errorHandler = (err, vm, info) => {
//     console.groupCollapsed(`%c Vue error: ${err}`, 'color:#FF8080;');
//     console.error(err);
//     // if (vm) {
//     //     console.groupCollapsed('%c Vue component', 'color:pink');
//     //     console.log(vm);
//     //     console.groupEnd();
//     // }

//     // if (info) {
//     //     console.groupCollapsed('%c Info', 'color:orange');
//     //     console.log('%c'+info, 'color:orange;');
//     //     console.groupEnd();
//     // }
//     console.groupEnd();
// };

Locale.use('en-US', enUS);
app.use(Collapse);
app.use(Switch);
// app.use(FloatingVue)
app.use(CollapseItem);
app.use(Notifications)
app.use(router);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Badge);
app.use(Tabbar);
app.use(VanImage);
app.use(Sidebar);
app.use(Radio);
app.use(RadioGroup);
app.use(NoticeBar);
app.use(vuetify);
app.use(ToastService);
app.use(Tab);
app.use(Tabs);
app.use(SidebarItem);
app.use(Countdown);
app.use(TabbarItem);
app.use(VueNumberFormat, VueCookieNext.getCookie('language') !== 'ENG' ? {prefix: '', decimal: '.', thousand: ' ',precision: 0}: {prefix: '', decimal: '.', thousand: ',',precision: 0})
app.use(Button);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Form);
app.use(Field);
app.use(Cell);
app.use(CellGroup);
app.use(PrimeVue);
app.use(Col);
app.use(Card);
app.use(Popup);
app.use(Divider);
app.use(Grid);
app.use(GridItem);
app.use(i18n);

app.use(VueCookieNext);
app.use(NavBar);
app.use(VueAxios, axios)
app.use(ActionSheet);
app.use(Icon);
app.use(Row);
app.use(ConfigProvider);
app.use(Dialog);

// eslint-disable-next-line vue/component-definition-name-casing
app.component('font-awesome-icon', FontAwesomeIcon);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Fa', FontAwesomeIcon);
app.component('ProgressSpinner',ProgressSpinner);
// app.component('CFormInput',CFormInput);
// app.component('CAlert',CAlert);
// app.component('CFormSelect',CFormSelect);
app.component('PrimeDialog', Dialog);
app.component('InputNumber', InputNumber);
app.component('PanelMenu', PanelMenu);

app.mount("#app");
